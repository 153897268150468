<template>
  <div class="inner-step">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col-md-10">
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <img :src="image" alt="failed" class="failed-image" />
              </div>
              <div class="text-center" v-html="$t('Failed.text')" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <footer class="footer-booking pt-3 pt-lg-4">
                <div class="row d-flex justify-content-between">
                  <div class="col-md-6">
                    <router-link
                      :to="{ name: 'ConfirmBooking', query: $route.query }"
                      class="btn is-danger"
                    >
                      {{ $t('Failed.btn1') }}
                    </router-link>
                  </div>
                  <div class="mt-3 mt-md-0 col-md-6">
                    <a href="https://ardi.one/" class="btn is-accent">
                      {{ $t('Success.btn2') }}
                    </a>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image from '../assets/images/Failed.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'BookingFailed',
  data() {
    return {
      image,
    };
  },
  computed: {
    ...mapGetters(['booking_details']),
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'payment_failed_or_canceled',
    });
    this.$emit('onChangeStep', 4);
    this.$store.dispatch('clear_booked_game');
    this.$store.dispatch('clear_booking_details');
    this.$store.dispatch('clear_booking_customer');
    this.$store.dispatch('clear_booking_amount');
    this.$store.dispatch('clear_discount');
  },
};
</script>

<style scoped lang="scss">
@import '../styles/utilities/utilities';
.failed-image {
  max-width: 80px;
  width: 100%;
  height: auto;
  display: inline-block;
  margin: 40px 0;
  @include media(md) {
    max-width: 150px;
    margin: 100px 0;
  }
}
.inner-step {
  background-image: url('../assets/images/forms-4.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 200px;
  @include media(md) {
    background-size: 400px;
  }
}
</style>
